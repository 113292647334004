import type { FooterBottomProps } from "components/TheFooterBottom.props"
import type { FooterProps } from "~/components/TheFooter.props"

const getLinks = () => {
  const locations = [
    "top-farmacia",
    "customer-area",
    "terms-of-sale",
    "highlighted"
    // "work-with-us"
  ]

  const linkList = locations.map((location) =>
    $fetch("/api/getFooterLink", {
      method: "GET",
      query: { limitQuery: 1, location }
    })
  )

  return Promise.all(linkList)
}

export const useFooterConnect = async () => {
  const { data: linkList } = await useAsyncData("footer", getLinks)

  const footerIcons: FooterBottomProps = {
    paymentIconsList: [
      { name: "postePay" },
      { name: "visa" },
      // { name: "visaElectron" },
      { name: "mastercard" },
      { name: "maestro" },
      // { name: "threePay" },
      { name: "applePay" },
      { name: "googlePayWhite" },
      { name: "postePayApp" },
      // { name: "googlePayBlack" },
      { name: "paypal" },
      { name: "bonifico" }
    ],
    shipmentIconsList: [{ name: "gls" }]
  }

  const footerInfo = computed(
    (): FooterProps => ({
      linkList: linkList.value ?? undefined,
      safeWebsiteIconsList: [
        {
          name: "trustpilot",
          path: "https://it.trustpilot.com/review/topfarmacia.it"
        },
        {
          name: "feedaty",
          path: "https://www.feedaty.com/recensioni/topfarmacia"
        },
        {
          name: "healthyMinister",
          path: "https://www.salute.gov.it/portale/temi/p2_6.jsp?lingua=italiano&id=4402&area=farmaci&menu=online&VOLID=C015399"
        }
      ],
      footerIconsList: {
        ...footerIcons
      }
    })
  )

  return { footerIcons, footerInfo }
}
